function productPage() {
    'use strict';

    const pictureSlider = new Swiper('#product-picture-slider');

    $('.quantity-selector .btn-plus').on('click', function(event) {
        event.preventDefault();
        const input = $(this).closest('.quantity-selector').find('.i-number');
        const max = input.data('max');
        const value = Math.min((input.val() >> 0) + 1, max);
        input.val(value);
    });

    $('.quantity-selector .btn-minus').on('click', function(event) {
        event.preventDefault();
        const input = $(this).closest('.quantity-selector').find('.i-number');
        const min = input.data('min');
        const value = Math.max((input.val() >> 0) - 1, min);
        input.val(value);
    });

    $('.addtocart-form').on('submit', function(event) {
        event.preventDefault();
        const $form = $(this);

        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    rewixUpdateCart($form.find('.current-qty:not(.hide)'));
                    $form.trigger('cart:update');
                    showMessageBox('success', msg['MSG_ADDEDTOCART']);
                }
                else if (data.code == 'INSUFFICIENT_STOCK') {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
                else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                    showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('#mobile-addtocart-submit').on('click', function(event) {
        const colorSelected = !exists('.color-select') || (exists('.color-select') && $('.color-select .option').toArray().some(option => $(option).data('selected')));
        const sizeSelected = !exists('.size-select') || (exists('.size-select') && $('.size-select .option').toArray().some(option => $(option).data('selected')));
        const cupSelected = !exists('.cup-select') || (exists('.cup-select') && $('.cup-select .option').toArray().some(option => $(option).data('selected')));

        if (!colorSelected && !$('.color-select').is('.open')) {
            event.preventDefault();
            event.stopPropagation();
            $('#select-size-mobile')[0].click();
            $('.color-select').find('.option:has(.check)').trigger('click');
        }
        else if ((!sizeSelected || !cupSelected) && !$('.size-select').is('.open')) {
            event.preventDefault();
            event.stopPropagation();
            $('#select-size-mobile')[0].click();
            $('.color-select, .cup-select').find('.option:has(.check)').trigger('click');
        }
    });

    $('.color-select, .size-select, .cup-select').find('.option').on('click', function() {
        $(this).data('selected', true);
    });

    $('.mobile-wishlist-buttons .add-to-wishlist').on('click', function() {
        $('.addtocart-form:visible .wishlist-buttons .add-to-wishlist').trigger('click');
    });

    $('.wishlist-buttons .add-to-wishlist').on('click', function() {
        const $form = $(this).closest('form');
        const productId = $form.find('[name*="qty_model"]').attr('name').replace('qty_model_', '');

        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action') + '?wishlist=true',
            data: $form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    const onSuccess = () => {
                        $form.trigger('wishlist:update');
                        const buttons = $('.wishlist-buttons, .mobile-wishlist-buttons');
                        buttons.find('.add-to-wishlist').addClass('hide');
                        buttons.find('.remove-from-wishlist').removeClass('hide');
                        showMessageBox('success', msg['MSG_ADDEDTOWISHLIST']);
                    }

                    if (typeof dataLayer !== 'undefined' && dataLayer) {
                        dataLayer.push({ ecommerce: null });
                        pushGTMEventWithCallback(500, {
                            event: 'add_to_wishlist',
                            ecommerce: window.ecommerce[productId],
                            eventCallback: GTMCallback(onSuccess)
                        });
                    }
                    else {
                        onSuccess();
                    }
                }
                else if (data.code == 'LOGIN_REQUIRED') {
                    window.location.href = $form.data('nexturl');
                }
                else {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $('.mobile-wishlist-buttons .remove-from-wishlist').on('click', function() {
        $('.addtocart-form:visible .wishlist-buttons .remove-from-wishlist').trigger('click');
    });


    $('.wishlist-buttons .remove-from-wishlist').on('click', function() {
        const $form = $(this).closest('form');
        const input = $form.find('[name*="qty"]').attr('name');
        let data = {};
        data[input] = 0;

        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action') + '?wishlist=true',
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    $form.trigger('wishlist:update');
                    const buttons = $('.wishlist-buttons, .mobile-wishlist-buttons');
                    buttons.find('.add-to-wishlist').removeClass('hide');
                    buttons.find('.remove-from-wishlist').addClass('hide');
                    showMessageBox('success', msg['MSG_REMOVEDTOWISHLIST']);
                }
                else if (data.code == 'LOGIN_REQUIRED') {
                    window.location.href = $form.data('nexturl');
                }
                else {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    const addtocartContainer = $('.product-container .addtocart');

    addtocartContainer.each(function() {
        if (exists('.color-select')) {
            showAvailableOptions(this, 'color-select');
            selectModel();
        }
    });
    $('[data-models]').css('visibility', 'visible');

    $('.addtocart [name*="select"]').on('change', function() {
        const container = $(this).closest('.addtocart');
        const name = $(this).attr('name');
        showAvailableOptions(container, name);
        selectModel();
        updateProductName();
    });

    $('#select-size-mobile, #addtocart-overlay').on('click', function() {
        if (exists('.cup-select')) {
            $('.size-select, .color-select, #mobile-product-name').addClass('with-cup-select');
            openMobileSelect();
        }
        else {
            openMobileSelect();
        }
    });

    new Swiper('.product-slider', {
        speed: 400,
        autoHeight: true,
        freeMode: true,
        slidesPerView: 1,
        mousewheel: {
            forceToAxis: true,
            invert: true,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    const productName = $('.product-name').first();
    const mobileProductName = $('#mobile-product-name');

    function updateProductName() {
        mobileProductName.find('.product-name, .price.hide').remove();

        if ($('#select-size-mobile').is('.open')) {
            const productNameClone = productName.clone(true, true);
            productNameClone.find('.mobile-wishlist-buttons').remove();
            mobileProductName.append(productNameClone);
        }
    }

    function openMobileSelect() {
        const selects = $('.size-select, .color-select, .cup-select, .current-qty, #addtocart-overlay, #select-size-mobile, #mobile-product-name');
        selects.toggleClass('open');

        $('.addtocart')
            .css('--size-offset', (() => {
                const option = $('.size-select .option');
                if (option.length > 0) {
                    const optionHeight = option.height();
                    const optionContainerHeight = option.parent().height();
                    return (optionContainerHeight - optionHeight) + 'px';
                }
                else {
                    return '0px';
                }
            })())
            .css('--color-offset', (() => {
                const option = $('.color-select .option');
                if (option.length > 0) {
                    const optionHeight = option.height();
                    const optionContainerHeight = option.parent().height();
                    return (optionContainerHeight - optionHeight) + 'px';
                }
                else {
                    return '0px';
                }
            })())
            .css('--cup-offset', (() => {
                const option = $('.cup-select .option');
                if (option.length > 0) {
                    const optionHeight = option.height();
                    const optionContainerHeight = option.parent().height();
                    return (optionContainerHeight - optionHeight) + 'px';
                }
                else {
                    return '0px';
                }
            })());

        updateProductName();

        if ($('body').is('.block-scroll')) {
            restorePageScroll();
        }
        else {
            blockPageScroll()
        }
    }

    $('.thumbnail').on('click', function() {
        const index = $(this).data('index');
        $('.thumbnail').removeClass('active');
        $(this).addClass('active');
        pictureSlider.slideToLoop(index);
    });
    pictureSlider.on('transitionEnd', function() {
        const thumbnail = $('.thumbnail:visible').get(pictureSlider.realIndex);
        $(thumbnail).trigger('click');
    });
    $('.thumbnail:visible').first().trigger('click');

    function showAvailableOptions(container, optionName) {
        const models = $(container).find(`[name="${optionName}"]:checked`).val().split(' ');
        
        const otherOptions = $(container).find('[name*="select"]').not(`[name="${optionName}"]`);
        const otherOptionsArray = otherOptions.toArray();
        otherOptionsArray.forEach(option => {
            $(option)
                .prop('disabled', false)
                .closest('.option')
                    .removeClass('hide')
        });

        for (let option of otherOptionsArray) {
            const $option = $(option);
            const optionModels = $option.val().split(' ');
            
            let model = null;
            for (const optionModel of optionModels) {
                if (models.includes(optionModel)) {
                    model = optionModel;
                    break;
                }
            }
            
            const qtyModel = $(`[name="qty_model_${model}"]`);
            if (qtyModel.length == 0 || qtyModel.data('max') == 0) {
                $option
                    .prop('disabled', true)
                    .closest('.option')
                        .addClass('hide');
            }
        }

        if (otherOptions.filter(':checked').is(':disabled')) {
            const keys = Array.from(new Set(otherOptionsArray.map(elem => $(elem).attr('name'))));
            for (const key of keys) {
                const firstOption = otherOptions.filter(`[name=${key}]`).not(':disabled').first();
                firstOption.trigger('click');
            }
        }

        addtocartContainer.removeClass('loading');
    }

    function selectModel() {
        const colorModels = addtocartContainer.find('[name="color-select"]:checked').val().split(' ');
        const sizeModels = addtocartContainer.find('[name="size-select"]:checked').val().split(' ');
        const cupModels = exists('[name="cup-select"]') ? addtocartContainer.find('[name="cup-select"]:checked').val().split(' ') : sizeModels;
        const selectedModel = colorModels.filter(elem => sizeModels.includes(elem) && cupModels.includes(elem))[0];

        if (selectedModel) {
            const currentQtyInput = $(`[name="qty_model_${selectedModel}"]`);
            
            $(`[name*="qty_model"]`)
                .prop('disabled', true)
                .closest('.current-qty')
                    .addClass('hide');

            currentQtyInput
                .prop('disabled', false)
                .closest('.current-qty')
                    .removeClass('hide');

            $('.addtocart-form [type=submit]').prop('disabled', currentQtyInput.data('max') == 0);

            $('[id*="price"]').addClass('hide');
            $(`#price-${selectedModel}`).removeClass('hide');
            $('.product-container').find('.thumbnail, .swiper-slide').filter('[data-models]').each(function() {
                const models = ($(this).data('models') || '').split(' ');
                if (models.includes(selectedModel)) {
                    $(this).show();
                }
                else {
                    $(this).hide();
                }
            });
            $('.thumbnail:visible').each(function(idx) {
                $(this).data('index', idx);
            });
            pictureSlider.update();
            $('.thumbnail:visible').first().trigger('click');
        }
    }
}

function updateCartCount(form, callback) {
    $('.header-utilities__wishlist').parent().load(window.location + '?' + new Date().getTime() + ' .header-utilities__wishlist:lt(1)', function() {
        if (callback) {
            callback();
        }
    });
}

function vimeoLoadingThumb(id){    
    var url = "https://vimeo.com/api/v2/video/" + id + ".json?callback=showThumb";

    var id_img = "#vimeo-" + id;

    var script = document.createElement( 'script' );
    script.src = url;

    $(id_img).before(script);
}


function showThumb(data){
    var id_img = "#vimeo-" + data[0].id;
    $(id_img).attr('src',data[0].thumbnail_medium);
}

function calculateTotal() {

    // Calculate availability total
    var availabilityTotal = 0;

    $('.availability').each(function () {
        availabilityTotal += parseInt($(this).text());
    });

    $('#availability-total').text(availabilityTotal);


    // Calculate order total
    var orderTotal = 0;

    $('.i-number').each(function () {
        orderTotal += parseInt($(this).val());
    });

    $('#order-total').text(orderTotal);


    // Calculate price total
    var singlePrice = parseFloat($('#price').text()).toFixed(2);
    var priceTotal = parseFloat(orderTotal * singlePrice).toFixed(2);
    $('#price-total').text(priceTotal + "€");
}


function preventNotNumericValues(e) {
    var charCode = (e.which) ? e.which : e.keyCode;

    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(charCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        (charCode === 65 && ( e.ctrlKey === true || e.metaKey === true ) ) ||
        (charCode >= 35 && charCode <= 40)) {
        // let it happen, don't do anything
        return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (charCode < 48 || charCode > 57)) && (charCode < 96 || charCode > 105)) {
        e.preventDefault();
    }
}

function InputNumber(option) {
    return this.each(function() {
        var $this = $(this);

        $this.keydown(preventNotNumericValues);
        $this.keyup(function() {
            option.onChange($this.get(0));
        });

        $this.max = $this.attr('data-max');
        $this.min = $this.attr('data-min');

        $('<a class="i-number-btn i-number-minus" href="#" role="button" tabindex="-1"><img src="/skins/current-skin/images/icons/min.svg" alt="Delete"/></a>')
            .insertBefore($this)
            .on('click', function(event) {
                event.preventDefault();
                var val = parseInt($this.val());
                var min = $this.min || 0;
                if ($.isNumeric(val) && val > min) {
                    $this.css('color', 'black');
                    $this.val(val - 1);
                } else {
                    $this.val(0);
                }
                option.onChange($this.get(0));
            });
        $('<a class="i-number-btn i-number-plus" href="#" role="button" tabindex="-1"><img src="/skins/current-skin/images/icons/plus.svg" alt="Add"/></a>')
            .insertAfter($this)
            .on('click', function(event) {
                event.preventDefault();
                var val = parseInt($this.val());
                var max = $this.max || Number.MAX_VALUE;
                if (!$.isNumeric(val)) {
                    $this.val(0);
                } else if (val >= $this.min && val < max) {
                    $this.val(val + 1);
                }
                option.onChange($this.get(0));
            });
    });
}

$.fn.inputNumber = InputNumber;

function loadQtyInputs() {
    $('.i-number').inputNumber({
        onChange: function(object) {
            $(object).closest('form').find('input[type="submit"]').prop('disabled', false);
        }
    });
}

function hasVerticalOverflow(element) {
    return element.offsetHeight < element.scrollHeight;
}