function cmsPage() {

    $('.swiper-button-next').each(function() {
        $(this).html('<img src="/skins/current-skin/images/icons/arrow-right-grey.svg" alt=">" loading="lazy" width="7" height="12"/>');
    });
    $('.swiper-button-prev').each(function() {
        $(this).html('<img src="/skins/current-skin/images/icons/arrow-left-grey.svg" alt="<" loading="lazy" width="7" height="12"/>');
    });

    var related = new Swiper('#swiper-related-blog',  {
        speed: 400,
        autoHeight: true,
        freeMode: true,
        spaceBetween: 30,
        slidesPerView: 3,
        mousewheel: {
            forceToAxis: true,
            invert: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
            slidesPerView: 1.4,
            spaceBetween: 20
            },
            // when window width is >= 480px
            480: {
            slidesPerView: 1.4,
            spaceBetween: 20
            },

            // when window width is >= 768px
            768: {
            slidesPerView: 3,
            spaceBetween: 20
            },
            // when window width is >= 769px
            769: {
            slidesPerView: 3,
            spaceBetween: 30
            },
        }
    });
}