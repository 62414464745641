/**
 * Initializes toggling elements within "scope". Use guide:
 * 
 * Terminology:
 *      toggler: element with the data-toggl attribute
 *      toggleable: element(s) matching the selector of data-toggl
 * 
 * Toggler attributes:
 *      data-toggl="selector": Indicates that this element will be a toggler and that the toggleable element(s) will be the ones matching "selector"
 *      data-toggl-slide: The toggling animation will be sliding up/down
 *      data-toggl-fade: The toggling animation will be fading in/out
 *      data-toggl-none: There will be no toggling animation, only the .open class will be added/removed (for custom CSS/JS animations)
 *      data-toggl-exclude="selector": The elements matching "selector" will be closed when the toggleable element opens
 * 
 * Toggler classes:
 *      .open: when the toggleable element is open
 * 
 * Toggleable classes:
 *      .open: when the element is open
 * 
 * Toggleable events:
 *      "toggl:open": opens the toggleable element
 *      "toggl:close": closes the toggleable element
 * 
 * @param scope Selector of the element within which activate toggling elements
 */
function initToggle(scope = document) {
    $(scope).find('[data-toggl]').not(initialized).each(function() {
        const toggler = $(this);
        const toggleableSelector = toggler.data('toggl');
        const toggleable = $(toggleableSelector);
        const slideAnimation = toggler.data('toggl-slide') !== undefined;
        const fadeAnimation = toggler.data('toggl-fade') !== undefined;
        const noAnimation = toggler.data('toggl-none') !== undefined;

        toggler.data('toggl-initialized', true);

        if (exists(toggleableSelector)) {
            const exclude = toggler.data('toggl-exclude');
            const $exclude = exists(exclude) ? $(exclude) : null;

            toggler.on('click', function() {
                if (toggler.is('.open')) {
                    toggler.removeClass('open');
                    toggleable.trigger('toggl:close');
                }
                else {
                    toggler.addClass('open');
                    toggleable.trigger('toggl:open');
                }
            });

            toggleable
                .on('toggl:open', function(event) {
                    event.stopPropagation();
                    toggler.addClass('open');
                    toggleable.addClass('open');

                    if (slideAnimation) {
                        toggleable.slideDown();
                    }
                    else if (fadeAnimation) {
                        toggleable.fadeIn();
                    }
                    else if (!noAnimation) {
                        toggleable.show();
                    }

                    if ($exclude) {
                        $exclude.not(toggleable).trigger('toggl:close');
                    }

                    toggleable.trigger('toggl:was-opened', toggleableSelector);
                })
                .on('toggl:close', function(event) {
                    event.stopPropagation();
                    toggler.removeClass('open');
                    toggleable.removeClass('open');

                    if (slideAnimation) {
                        toggleable.slideUp();
                    }
                    else if (fadeAnimation) {
                        toggleable.fadeOut();
                    }
                    else if (!noAnimation) {
                        toggleable.hide();
                    }

                    toggleable.trigger('toggl:was-closed', toggleableSelector);
                });

            toggleable.filter('.open').trigger('toggl:open');
            toggleable.not('.open').trigger('toggl:close');
        }
    });

    function initialized() {
        return $(this).data('toggl-initialized') !== undefined;
    }
}