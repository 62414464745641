var msg = {
	ERR_CARRIERMISSING: 'Devi selezionare un tipo di spedizione',
	ERR_CARRIER_COST_MISSING: 'Nessuna tariffa applicabile per nazione, corriere e peso dell\'ordine',
	ERR_CAPTCHA: 'Codice Recaptcha errato',
	ERR_CLIENTMANDATORYFIELDS: 'I campi contrassegnati sono obbligatori',
	ERR_FILLEMAILFIELD: 'Compila il campo email',
	ERR_INVALIDMAIL: 'Email non valida',
	ERR_LOGININVALID: 'Email e/o password non validi',
	ERR_LOGINREQUIRED: 'Devi effettuare l\'accesso',
	ERR_MISSINGFIELDS: 'Completa correttamente tutti i campi obbligatori',
	ERR_NOSIZESELECTED: 'Devi prima selezionare una taglia',
	ERR_PRIVACY: 'Devi accettare le condizioni',
	ERR_USEREXISTS: 'Utente già esistente',
    ERR_DATE_FROM: 'La data deve essere successiva a :from',
    ERR_DATE_FROM_TO: 'La data deve essere tra :from e :to',
    ERR_DATE_TO: 'La data deve essere precedente a :to',
	MSG_DEFERRED_DISPATCH_COMPUTATION: 'Sarà abilitata la sola prenotazione dell\'ordine e le spese di spedizione verranno comunicate via email',
	MSG_ADDEDTOWISHLIST: 'Prodotto aggiunto alla lista dei desideri',
	MSG_REMOVEDTOWISHLIST: 'Prodotto rimosso dalla lista dei desideri',
	MSG_ADDEDTOCART: 'Prodotti aggiunti al carrello',
	MSG_WAIT: 'Attendere...',
	MSG_SUBJECT_PRODUCTSHARE: ' ha condiviso qualcosa con te',
	MSG_SENDER_PRODUCTSHARE: 'Il tuo nome',
	MSG_MESSAGE_PRODUCTSHARE: 'Messaggio',
	MSG_MAILSENT: 'E-Mail inviate',
	MSG_MIXED_INTANGIBLE: 'I prodotti nel carrello non possono essere acquistati insieme al prodotto selezionato',
	MSG_MIXED_CART: 'I prodotti nel carrello non possono essere acquistati insieme al prodotto selezionato perchè si trovano in magazzini diversi',
	MSG_INSUFFICIENT_STOCK: 'Al momento la disponibilità del prodotto non è sufficiente',
	MSG_APPLIEDVATCHANGED: 'Dopo questo aggiornamento dei dati di fatturazione, l\'IVA applicata al tuo ordine è cambiata',
	MSG_GENERIC_ERROR: 'Spiacente, si è verificato un errore',
	MSG_COUPON_ERROR: 'Codice coupon inserito non valido o inserito precedentemente',
	MSG_COUPON_VALID: 'Codice coupon inserito validato con successo',
	MSG_RECOVEREMAILSENT: 'Controlla la tua email e clicca sul link per confermare la modifica',
	MSG_RECOVEREMAILSENT_ERROR: 'Attenzione: Non sei registrato con questo indirizzo e-mail o stai già reimpostando una nuova password',
	MSG_EXTRAUELIMIT: 'L\'ammontare minimo del tuo ordine non può essere inferiore a 2000.00 &euro;',
	MSG_MUST_ACCEPT_TERMS: 'Devi accettare i termini d\'uso',
	MSG_RULE_REJECT: 'Il prodotto non può essere aggiunto al carrello per via della regola :rule',
	MSG_SELECT_COLOR: 'Seleziona un colore',
	MSG_SELECT_CUP: 'Seleziona una coppa',
	MSG_SELECT_SIZE: 'Seleziona una taglia',
	LABEL_ARTICLES: 'articoli',
	LABEL_WARNING: 'Attenzione',
	LABEL_NOTAPPLICABLE: 'Non applicabili',
	LABEL_ALREADYREGISTEREDUSERES: 'Utenti già registrati',
	LABEL_SENTINVITES: 'Inviti inviati',
	LABEL_NOTSENTINVITES: 'Inviti non inviati',
	LABEL_INVITES_ALREADY_SENT: 'Utenti già invitati',
	LABEL_SUBMIT: 'Invia',
	LABEL_SELECTALL: 'Seleziona tutti',
	MSG_MESSAGE_SENT: 'Messaggio inviato!',
	MSG_RETURN_REQUEST_SUCCESS: 'Richiesta di reso creata con successo',
	MSG_SAVE_SUCCESS: 'Salvataggio avvenuto con successo',
	MSG_SUBSCRIBED: 'Iscritto con successo',
	MSG_PROFILECONSENT_SAVED: 'Le tue preferenze di contatto sono state aggiornate',
	MSG_SAMPLE_NOT_FOUND: 'Non è stato trovato nessun campione per il prodotto scelto',
	MSG_PEC_SDI_INCOMPLETE: 'Inserisci almeno un campo tra PEC e SDI',
	MSG_PASSWORD_NOT_EQUAL: 'La due password non corrispondono o non sono valide',
	ERR_USER_ALREADY_REGISTERED: 'Utente già registrato',
	MSG_DELETEALLPRODUCTS: 'Sei sicuro di voler eliminare tutti i prodotti dal carrello?',
	DELETEALLPRODUCTSERROR: 'Errore di eliminazione dei prodotti dal carrello',
	MSG_PASSWORD_OK: 'La password è stata modificata con successo',
    BUTTONS_CONFIRM: 'Conferma',
    BUTTONS_CANCEL: 'Annulla',
    MSG_PASSWORD_NOT_COMPLEX: 'La password non è abbastanza complessa, deve contenere una lettera maiuscola, un numero, e almeno 8 caratteri',
	passwordnotmatchwithconfirm: 'Le due password non corrispondono',
	wrongpassword: 'Password errata',
	'myunderwearb2c.loyality.unexisting': 'La Fidelity Card che hai inserito non esiste',
	'myunderwearb2c.loyality.valitationfailed': 'Errore durante la verifica della validità della tessera',
	'myunderwearb2c.loyality.mismatch': 'La partita IVA che hai inserito non coincide con quella usata per registrare la Fidelity Card',
	'profile.user.loyalityalreadyused': 'La Fidelity Card che hai inserito è già utilizzata',
	'login.user.wrongpassword': 'Password errata',
	'login.user.unexisting': 'Utente non trovato',
	'login.user.disabled': 'Utente disabilitato o in attesa di validazione',
}