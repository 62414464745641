function menuComponent() {
    $('html').on('mobile-menu:open', function(event, target) {
        $(target).addClass('opening');
        setTimeout(function() {
            $(target)
                .removeClass('opening')
                .addClass('open');
        }, 500);
        blockPageScroll();
    });

    $('html').on('mobile-menu:close', function(event, target) {
        $(target).addClass('closing');
        setTimeout(function() {
            $(target).removeClass(['open', 'closing']);
            restorePageScroll();
        }, 500);
    });

    setupMobileMenu('#mobile-menu');
}

function setupMobileMenu(selector) {
    const menu = $('.mobile-menu').filter(selector);
    const toggle = $(`.mobile-menu-toggle[data-target="${selector}"]`)

    toggle.on('click', function () {
        $(this).trigger('mobile-menu:open', $(this).data('target'));
    });

    menu.find('.overlay, .close-mobile-menu .button').on('click', function () {
        const id = '#' + $(this).closest('.mobile-menu').attr('id');
        $(this).trigger('mobile-menu:close', id);
    });
}

$('#profile-mobile').on('click', function() {
    $('#dropdown-mobile').toggle();
});

$('#dropdown-mobile-anchor').on('click', function() {
    $('#dropdown-mobile').hide();
});