'use strict';

var dropCookie = true;


function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    if (dropCookie) {
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}

function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, '', -1);
}

function exists(selector) {
    try {
        return $(selector).length > 0;
    }
    catch {
        return false;
    }
}

function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
  
    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
  
    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';
  
    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;
  
    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
  
    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';
  
  
    textArea.value = text;
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      document.body.removeChild(textArea);
      return true
    } catch (err) {
      document.body.removeChild(textArea);
      return false
    }
}

function loadScript(url) {
    return new Promise((resolve, reject) => {
        $.ajax({
            method: 'GET',
            url: url,
            dataType: 'script',
            async: true,
            success: () => resolve(),
            error: () => reject()
        });
    });
}

function loadCaptcha(selector='.g-recaptcha') {
    if (exists(selector)) {
        const recaptcha = $(selector);
        if (!recaptcha.data('loaded')) {
            loadScript('https://www.google.com/recaptcha/api.js')
                .then(() => recaptcha.data('loaded', 'true'));
        }
    }
}

function loadProvinces(countryId) {
    return new Promise((resolve) => {
        $.ajax({
            method: 'GET',
            url: `/restful/countries/provinces?countryId=${countryId}`,
            success: function(data) {
                resolve(data.results);
            },
            error: function() {
                resolve([]);
            }
        });
    });
}

function getQueryParams(url) {
    let queryParams = {};
    let [_, params] = url.split('?');

    if (params) {
        for (let param of params.split('&')) {
            let [key, value] = param.split('=');
            queryParams[key] = value || '';
        }
    }

    return queryParams;
}

function getQueryParam(variable) {
  return getQueryParams(window.location.href)[variable] || '';
}

function blockPageScroll() {
    $('body').addClass('block-scroll');
}

function restorePageScroll() {
    $('body').removeClass('block-scroll');
}

$(function() {

    initTelDropdowns();

    if (getQueryParam('errorCode') == '263') {
        $('#login-form').addClass('hide');
        $('#login-form-otp').removeClass('hide');
    }

});

function GTMCallback(callback) {
    return (containerId) => {
        if ((!containerId || containerId.startsWith("GTM-")) && typeof callback === "function") {
            callback();
        }
    }
}

function pushGTMEventWithCallback(timeout, options) {
    const dataLayer = window.dataLayer ?? [];

    let callbackExecuted = false;
    dataLayer.push({
        ...options,
        ...(options.eventCallback
            ? {
                eventCallback: GTMCallback(() => {
                    if (!callbackExecuted) {
                        callbackExecuted = true;
                        options.eventCallback();
                    }
                })
            }
            : {}
        )
    });

    if (options.eventCallback) {
        setTimeout(() => {
            if (!callbackExecuted) {
                callbackExecuted = true;
                options.eventCallback();
            }
        }, timeout);
    }
}