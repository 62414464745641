function homePage() {

  $('html').on('cart:update', function() {
    $.ajax({
      method: 'GET',
      url: window.location.href,
      dataType: 'html',
      success: function(data) {
        const response = $('<div>').append($(data));
        $('#cart-icon').replaceWith(response.find('#cart-icon'));
        $('#cart-icon-mobile').replaceWith(response.find('#cart-icon-mobile'));
      }
    });
  });

  $('html').on('wishlist:update', function() {
    $.ajax({
      method: 'GET',
      url: window.location.href,
      dataType: 'html',
      success: function(data) {
        const response = $('<div>').append($(data));
        $('#wishlist-icon').replaceWith(response.find('#wishlist-icon'));
      }
    });
  });

  var slidesPerView = 1.4;
  var spaceBetween = 10;

    if (Foundation.MediaQuery.is('large')) {
      slidesPerView = 3.2;
      spaceBetween = 10;
    } else if (Foundation.MediaQuery.is('medium')) {
      slidesPerView = 2.1;
      spaceBetween = 10;
    } else {
      slidesPerView = 1.6;
      spaceBetween = 0;
    }



    var mainslider = new Swiper('#main-slider', {
      speed: 3000,
      autoplay: true,
      slidesPerView: 1,
      mousewheel: {
        forceToAxis: true,
      invert: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });

    var newarriavals = new Swiper('.swiper-new-arrivals', {
      speed: 400,
      autoHeight: true,
      freeMode: true,
      spaceBetween: 10,
      slidesPerView: slidesPerView,
      mousewheel: {
        forceToAxis: true,
      invert: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.6
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1.6
        },

        // when window width is >= 768px
        768: {
          slidesPerView: 3.1
        },
        // when window width is >= 769px
        769: {
          slidesPerView: 3.2
        },
      }
    });

    var newproducts = new Swiper('.swiper-new-products', {
      speed: 400,
      autoHeight: true,
      freeMode: true,
      slidesPerView: 4,
      spaceBetween: spaceBetween,
      mousewheel: {
        forceToAxis: true,
      invert: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.4,
          spaceBetween: 30
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1.4,
          spaceBetween: 30
        },

        // when window width is >= 768px
        768: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 769px
        769: {
          slidesPerView: 3,
          spaceBetween: 30
        },
      }
    });

    var related = new Swiper('.swiper-related', {
      speed: 400,
      autoHeight: true,
      freeMode: true,
      spaceBetween: spaceBetween,
      slidesPerView: 4,
      mousewheel: {
        forceToAxis: true,
      invert: true,
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.4,
          spaceBetween: 30
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1.4,
          spaceBetween: 30
        },

        // when window width is >= 768px
        768: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 769px
        769: {
          slidesPerView: 4,
          spaceBetween: 30
        },
      }
    });

    // TMP FIX
    $('.be-inspired .large-8').removeClass('align-items-center').addClass('align-items-start');
    // TMP FIX

    var inspired = new Swiper('.swiper-be-inspired', {
      speed: 400,
      autoHeight: true,
      freeMode: true,
      spaceBetween: 20,
      slidesPerView: 1,
      mousewheel: {
        forceToAxis: true,
      invert: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
      },
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2.1
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2.1
        },

        768: {
          slidesPerView: 2.1
        },
        // when window width is >= 769px
        769: {
          slidesPerView: 1
        },
      }
    });

    var productImages = new Swiper('.swiper-product', {
      speed: 400,
      autoHeight: true,
      freeMode: true,
      spaceBetween: 30,
      slidesPerView: 1,
      mousewheel: {
        forceToAxis: true,
      invert: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    $('.nav-slide').click(function() {
      productImages.slideTo($(this).attr('data-image'), 1000);
    })

    if ($('.video').length > 0) {
      $('.video').height($('.video').width()/1.723)
    }


    // Play Youtube video
    $('#play').on('click touchend', function() {
      const video = $("#video-home");
      video.attr('src', video.data('src'));
      $("#play>img").hide();
      video.parent().show();
    });
}


//banner clickable
$('*[data-link]').click(function() {
  window.location.href= $(this).attr('data-link')
});
