function cartPage() {

    $('.quantity-selector .btn-plus').on('click', function(event) {
        event.preventDefault();
        const input = $(this).closest('.quantity-selector').find('.i-number');
        const max = input.data('max');
        const value = Math.min((input.val() >> 0) + 1, max);
        input.val(value);
    });

    $('.quantity-selector .btn-minus').on('click', function(event) {
        event.preventDefault();
        const input = $(this).closest('.quantity-selector').find('.i-number');
        const min = input.data('min');
        const value = Math.max((input.val() >> 0) - 1, min);
        input.val(value);
    });

    $('.quantity-selector .i-number').on('input', function() {
        let value = $(this).val().replace(/[^0-9]/g, '') >> 0;
        const min = $(this).data('min');
        const max = $(this).data('max');

        value = Math.max(value, min);
        value = Math.min(value, max);
        $(this).val(value);
    });

    $('.quantity-selector .i-number').on('keydown', function(event) {
        const key = event.key.toUpperCase();
        if (key == 'ENTER') {
            event.preventDefault();
            $(this).trigger('submit');
        }
        else if (key == 'ARROWUP') {
            event.preventDefault();
            $(this).closest('.quantity-selector').find('.btn-plus').trigger('click');
        }
        else if (key == 'ARROWDOWN') {
            event.preventDefault();
            $(this).closest('.quantity-selector').find('.btn-minus').trigger('click');
        }
    });

    $('.points-title').on('click', function() {
        $('.points-div').slideToggle()
    });

    $('.coupon-title').on('click', function() {
        $('.coupon-div').slideToggle()
    });

    $('#close-modal-wishlist').on('click', function () {
        $('#shareWishlistPopup').foundation('close');
    });

    $('.convert-point').on('click', function() {
        $.ajax({
            type: 'POST',
            url: '/restful/custom/myunderwearb2c/loyality/usepoints',
            data: 'count=' + 1,
            success: function(data) {
                if (data.success) {
                    window.location.reload();
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    })

    $('.carrier-form').change(function() {
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('select[name=carrier_id]').val(),
            },
            headers: {'Accept': "application/json"},
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    // update cart quantity
    $('.cart-update').on('submit', function(event) {
        event.preventDefault();
        const $form = $(this);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    rewixUpdateCart($form);
                    setTimeout(() => {
                        window.location.href = '/current/cart';
                    }, 500);
                }
                else if (data.code == 'INSUFFICIENT_STOCK') {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // remove cart size
    $('.btn-cart-remove').on('click', function(event) {
        event.preventDefault();
        let data = {};
        const $this = $(this);
        data[$this.attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $this.attr('data-action'),
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    rewixUpdateCart($this.closest('form'), true);
                    setTimeout(() => {
                        window.location.href = '/current/cart';
                    }, 500);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // remove cart item
    $('.btn-cart-remove-all').on('click', function(event) {
        event.preventDefault();
        const $this = $(this);
        var currentTable = $this.attr('data-product-id');
        var inputs = $(currentTable).find('.i-number');
        var data = {};

        inputs.each(function () {
            data[$this.attr('name')] = 0;
        });

        $.ajax({
            type: 'POST',
            url: $this.attr('data-action'),
            data: data,
            success: function(data) {
                if (data.success) {
                    rewixUpdateCart($('#cart-container .cart-details'), true);
                    setTimeout(() => {
                        window.location.href = '/current/cart';
                    }, 500);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // Coupon form
    $(".burn-coupon-form").submit(function(event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        setTimeout(function(){ window.location.href = "/current/cart"; }, 1000);
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

    $('#gestpay-form').submit(function(e){
        e.preventDefault();
        var action = $(this).attr('action');
        $.ajax({
            type : 'POST',
            url : action,
            async: false,
            beforeSend : function(xhr) {
                xhr.setRequestHeader("Accept", "application/json")
            },
            dataType : "json",
            success : function(data){
                if(data.success){
                    window.location.href = data.message;
                }
            }
        });
    });


    // calculate each product total
    function singleProductQty() {
        $('.cart-product').each(function () {
            var inputs = $(this).find('.i-number');
            var total = 0;

            inputs.each(function () {
                total += parseInt($(this).val());
            });

            $(this).find('.single-product-qty').text(total);
        });
    }


    // calculate total quantity
    function totalQty() {
        var total = 0;

        $('.single-product-qty').each(function () {
            total += parseInt($(this).text());
        });

        $('#total-qty').text(total);
    }

    // calculate total quantity
    function totalDiscount() {
        var total = 0;

        $('.discount-value').each(function () {
            var currentValue = parseFloat($(this).text()).toFixed(2);
            total = parseFloat(total) + parseFloat(currentValue);
        });

        $('#total-discount').text(parseFloat(total).toFixed(2) + "€");
    }

    singleProductQty();
    totalQty();
    totalDiscount();

    // remove all products
    $('.cart-remove-all-products').on('click', function(event) {
        event.preventDefault();
        showQuestionBox(msg['MSG_DELETEALLPRODUCTS'], () => {
            const qtyInputs = $('#cart-container .cart-details .show-for-large [name*="qty_model"]');
            $.ajax({
                type: 'POST',
                url: '/restful/shopping/public/checkout/addtocart',
                data: qtyInputs.serializeArray().map(pair => ({name: pair.name, value: 0})),
                dataType: 'json',
                success: function(data) {
                    if (data.success) {
                        rewixUpdateCart($('#cart-container .cart-details .show-for-large'), true);
                        setTimeout(() => {
                            window.location.href = '/current/cart';
                        }, 500);
                    }
                    else {
                        showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
                    }
                },
                error: function() {
                    showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
                }
            });
        });
    });

    $('.btn-wishlist-to-cart').on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        const $form = $(this).closest('form');
        const model_id = $form.find('[name=model_id]').val();

        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: `qty_model_${model_id}=1`,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    $form.trigger('cart:update');
                    showMessageBox('success', msg['MSG_ADDEDTOCART']);
                }
                else if (data.code == 'INSUFFICIENT_STOCK') {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
                else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                    showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.btn-wishlist-remove .close-popup.wishlist').on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        const product = $(this).closest('.wishlist-product');
        const $form = product.find('form');
        const model_id = $form.find('[name=model_id]').val();

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action') + '?wishlist=true',
            data: `qty_model_${model_id}=0`,
            success: function() {
                window.location.href = '/current/profile/wishlist';
            },
            error: function() {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });
}

function removeAllProducts() {
    $('div.alert-box').html('');
    $('.alert-box').hide()
    $('body').removeClass('block-scroll')
    var products = document.getElementsByClassName('cart-update').length;
    var i = 0;

    $('.cart-update').each(function() {
        var currentTable = $(this).find('.cart-table');
        var inputs = $(currentTable).find('.i-number');
        var data = {};

        inputs.each(function () {
            data[$(this).attr('name')] = 0;
        });

        $.ajax({
            type: 'POST',
            url: '/restful/shopping/public/checkout/addtocart',
            data: data,
            success: function(data) {
                i++;
                if (i == products) {
                    rewixUpdateCart($('#cart-container .cart-details'), true);
                    setTimeout(() => {
                        window.location.href = '/current/cart';
                    }, 500);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
            }
        });
    });

}

$('#copyLink').click(function() {
    var ret = copyTextToClipboard($('#linkToCopy').val())
    if (ret) {
        $('#copyLink').html('Link copiato!')
    }
})