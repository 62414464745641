// Custom rules
$.validator.addMethod("complexPassword", function(value, element) {
    return this.optional(element) || value.length >= 8 && /[a-z]/g.test(value) && /[A-Z]/g.test(value) && /[0-9]/g.test(value);
}, msg['MSG_PASSWORD_NOT_COMPLEX']);

$.validator.addMethod('vat_prefix', function(value, element) {
    const required = $(element).is(':required');
    if (required) {
        return this.optional(element) || /^[A-Z]{2}.*$/gm.test(value);
    }
    else {
        return this.optional(element) || true;
    }
});

$.validator.addMethod('vat', function(value, element) {
    const vatPatternsEU = {
        13: /^ATU[0-9]{9}$/,
        20: /^BE[0-9]{10}$/,
        22: /^BU[0-9]{9,10}$/,
        53: /^CY[0-9]{9}[A-Z]{1}$/,
        97: /^HR[0-9]{11}$/,
        58: /^DK([0-9]{8}|[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2})$/,
        63: /^EE[0-9]{9}$/,
        69: /^FI[0-9]{8}$/,
        74: /^FR[0-9A-Z]{2}[0-9]{9}$/,
        56: /^DE[0-9]{9}$/,
        88: /^EL[0-9]{9}$/,
        99: /^HU[0-9]{8}$/,
        101: /^IE[0-9]{7}[A-Z]{1,2}$/,
        108: /^IT[0-9]{11}$/,
        132: /^LV[0-9]{11}$/,
        130: /^LT([0-9]{9}|[0-9]{12})$/,
        131: /^LU[0-9]{8}$/,
        147: /^MT[0-9]{8}$/,
        160: /^NL[0-9]{9}B[0-9]{2}$/,
        174: /^PL[0-9]{10}$/,
        178: /^PT[0-9]{9}$/,
        183: /^RO[0-9]{2,10}$/,
        195: /^SK[0-9]{10}$/,
        193: /^SI[0-9]{8}$/,
        67: /^ES[0-9A-Z]{1}[0-9]{7}[0-9A-Z]{1}$/,
        190: /^SE[0-9]{12}$/,
    }
    const required = $(element).is(':required');
    if (required) {
        return this.optional(element) || Object.values(vatPatternsEU).some(pattern => pattern.test(value));
    }
    else {
        return this.optional(element) || true;
    }
});

$.validator.addMethod('pec_or_sdi', function(value, element, other) {
    const $other = $(other);
    
    return /*(this.optional(element) != false) ||*/ not(not(value) && not($other.val()));
           /* ^ FIXME: dependency-mismatch (???) */
});

$.validator.addMethod('date_range', function(value, element, params) {
    const date = new Date(value);
    let [from, to] = [true, true];

    if (params.from) {
        from = date >= new Date(params.from);
    }
    if (params.to) {
        to = date <= new Date(params.to);
    }

    console.log(this.optional(element) || (to && from), to, from)

    return this.optional(element) || (to && from);
}, function(params, element) {
    if (params.from && params.to) {
        return msg['ERR_DATE_FROM_TO'].replace(':from', toDateString(params.from)).replace(':to', toDateString(params.to));
    }
    else if (params.from) {
        return msg['ERR_DATE_FROM'].replace(':from', toDateString(params.from));
    }
    else {
        return msg['ERR_DATE_TO'].replace(':to', toDateString(params.to));
    }

    function toDateString(date) {
        return new Date(date).toLocaleDateString("it-IT");
    }
});

// Custom error messages
$.validator.messages.required = msg['ERR_MISSINGFIELDS'];
$.validator.messages.email = msg['ERR_INVALIDMAIL'];
$.validator.messages.equalTo = msg['MSG_PASSWORD_NOT_EQUAL'];
$.validator.messages.vat_prefix = msg['ERR_VAT_PREFIX'];
$.validator.messages.vat = msg['ERR_VAT'];
$.validator.messages.pec_or_sdi = msg['ERR_PEC_OR_SDI'];