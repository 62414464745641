function loginPage() {
    // Fetch errorMessage from params
    if (window.location.search != '') {
        if (document.getElementById('shoptype') != undefined) {
            var type = GetUrlValue('business');
            document.getElementById('shoptype').value = 'type:' + type;
        }
    }

    // password recovery form
    $('#password-new-form-email').validate({
        submitHandler: function(form) {
            const $form = $(form);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function () {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        const email = $('#recoveremail').val();
                        showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                        $('#password-new-form-email').addClass('hide');
                        $('#password-new-form-token').removeClass('hide')
                            .find('[name="email"]').val(email);
                    }
                    else {
                        showMessageBox('danger', msg[data.errors.message] || msg['ERR_INVALIDMAIL']);
                    }
                },
                failed: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function () {
                    $form.find(':input').prop('disabled', false);
                }
            });
    
        }
    });

    $('#password-new-form-token').validate({
        rules: {
            token: 'required',
            password: {
                required: true,
                complexPassword: true
            }
        },
        submitHandler: function (form) {
            const $form = $(form);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_OK']);
                        setTimeout(() => {
                            window.location.href = $('#password-new-form-token').attr('next-url')
                        }, 5000);
                    }
                    else if (data.errors.message == 'fault') {
                        showMessageBox('danger', msg['ERR_WRONG_TOKEN']);
                    }
                    else {
                        showMessageBox('danger', msg[data.errors.message] || msg['MSG_GENERIC_ERROR']);
                    }
                },
                failed: function (data) {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function (data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });


    // subscribe form
    $('#subscribe-form').validate({
        rules: {
            password1: {
                required: true,
                complexPassword: true
            },
            password2: {
                required: true,
                equalTo: '#password1'
            },
            birthday: {
                required: true,
                date_range: {
                    to: Date.now()
                }
            }
        },
        errorPlacement: function(error, element) {
            if ($(element).is('[type=date]')) {
                $(error)
                    .css({
                        'position': 'relative',
                        'top': '-8px'
                    })
                    .insertAfter($(element).parent());
            }
            else {
                $(error)
                    .css({
                        'position': 'relative',
                        'top': '-8px'
                    })
                    .insertAfter(element);
            }
        },
        invalidHandler: function() {
            if ($('#password1').val() != $('#password2').val()) {
                showMessageBox('danger', msg['MSG_PASSWORD_NOT_EQUAL']);
            }
        },
        submitHandler: function(form) {
            const $form = $(form);
            const birthday = $form.find('.date-birth[name="birthday"]').val().replace(/-/g, '/');

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize() + '&birth=' + birthday,
                dataType: 'json',
                beforeSend: function (data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        if (typeof dataLayer !== 'undefined' && dataLayer) {
                            pushGTMEventWithCallback(500, {
                                event: 'sign_up',
                                eventCallback: GTMCallback(function() {
                                    window.location.href = $form.data('redirectsuccess');
                                })
                            })
                        }
                        else {
                            window.location.href = $form.data('redirectsuccess');
                        }
                    }
                    else {
                        showMessageBox('danger', msg[data.errors.message]);
                    }
                },
                failed: function (data) {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function (data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });
}

function GetUrlValue(VarSearch) {
    var SearchString = window.location.search.substring(1);
    var VariableArray = SearchString.split('&');
    for (var i = 0; i < VariableArray.length; i++) {
        var KeyValuePair = VariableArray[i].split('=');
        if (KeyValuePair[0] == VarSearch) {
            return KeyValuePair[1];
        }
    }
}
