$(document).ready(function() {
    homePage();

    if (exists('#cart-container')) {
        cartPage();
    }
    else if (exists('#catalog-container')) {
        catalogPage();
    }
    else if (exists('#checkout-container')) {
        checkoutPage();
    }
    else if (exists('.cms-wrapper')) {
        cmsPage();
    }
    else if (exists('#designers-container')) {
        designersPage();
    }
    else if (exists('#login-subscribe-container')) {
        loginPage();
    }
    else if (exists('#profile-container')) {
        profilePage();

        if (exists('.table-orders')) {
            profileOrdersPage();
        }
    }
    else if (exists('.product-container')) {
        productPage();
    }

    if (exists('#contact-form')) {
        contactComponent();
    }
    if (exists('footer')) {
        footerComponent();
    }
    if (exists('#main-menu, #mobile-menu')) {
        menuComponent();
    }
    if (exists('#subscribe-newsletter')) {
        newsletterComponent();
    }
    if (exists('.product-item')) {
        productComponent();
    }
    if (exists('#search-mobile')) {
        searchComponent();
    }
    if (exists('.cart-summary-mobile')) {
        cartSummaryComponent();
    }

    initToggle();

    $(window).on('resize', function() {
        switch (Foundation.MediaQuery.current) {
            case 'small':
                $('.hide-for-large, .hide-for-medium').removeClass('hidden');
                $('.show-for-large, .show-for-medium').addClass('hidden');
                break;

            case 'medium':
                $('.show-for-medium, .hide-for-large').removeClass('hidden');
                $('.show-for-large, .hide-for-medium').addClass('hidden');
                break;

            case 'large':
                $('.show-for-medium, .show-for-large').removeClass('hidden');
                $('.hide-for-medium, .hide-for-large').addClass('hidden');
                break;

            default:
                break;
        }
    });
});