function profilePage() {
    'use strict';
    var currentCountry = $( "#country" ).find('option:selected').data('eu');

    if (currentCountry == 'EU') {
        $('#cfpiva-div').removeClass('hide');
        $('#pec-sdi-div').addClass('hide');
        $('#pec-div').addClass('hide');
        $('#sdi-div').addClass('hide');
        $('#cfpiva').attr('required','required');
    } else if (currentCountry == 'IT') {
        $('#cfpiva-div').removeClass('hide');
        $('#pec-sdi-div').removeClass('hide');
        $('#pec-div').removeClass('hide');
        $('#sdi-div').removeClass('hide');
        $('#cfpiva').attr('required','required');
    } else if (currentCountry != undefined) {
        $('#cfpiva-div').addClass('hide');
        $('#pec-sdi-div').addClass('hide');
        $('#pec-div').addClass('hide');
        $('#sdi-div').addClass('hide');
        $('#cfpiva').removeAttr('required');
    }

    $('#copy-dispatch').on('click', function(event) {

        document.getElementById('addressee').value = document.getElementById('addressee_disp').value
        document.getElementById('cap').value = document.getElementById('cap_disp').value
        document.getElementById('city').value = document.getElementById('city_disp').value
        document.getElementById('number').value = document.getElementById('number_disp').value
        document.getElementById('prov').value = document.getElementById('prov_disp').value
        document.getElementById('street').value = document.getElementById('street_disp').value

        $('#country').val(document.getElementById('country_disp').value)

        var currentCountry = $( "#country" ).find('option:selected').data('eu');

        if (currentCountry == 'EU') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').attr('required','required');
        } else if (currentCountry == 'IT') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').removeClass('hide');
            $('#pec-div').removeClass('hide');
            $('#sdi-div').removeClass('hide');
            $('#cfpiva').attr('required','required');
        } else if (currentCountry != undefined) {
            $('#cfpiva-div').addClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').removeAttr('required');
        }

    });

    $( "#country" ).change(function() {
        var selected = $(this).find('option:selected');
        var requiredVat = selected.data('eu'); 

        if (requiredVat == 'EU') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').attr('required','required');
            $('#cfpiva').val("")
        } else if (requiredVat == 'IT') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').removeClass('hide');
            $('#pec-div').removeClass('hide');
            $('#sdi-div').removeClass('hide');
            $('#cfpiva').attr('required','required');
            $('#cfpiva').val("")
        } else {
            $('#cfpiva-div').addClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').removeAttr('required');
            $('#cfpiva').val("")
        }

    }); 
    
    function submitUserUpdateForm(form) {
        const $form = $(form);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                } else {
                    showMessageBox('danger', msg[data.errors.message]);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    }

    $('#user-update-data-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: function(form) {
            if ($('#user-update-data-form').valid()) {
                submitUserUpdateForm(form);
            }
            else {
                showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
            }
        }
    });

    $('#user-update-dispatch-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-invoice-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });
    
    $('#password-change-form').validate({
        rules: {
            current: 'required',
            password: {
                required: true,
                complexPassword: true
            },
            password_confirm: {
                required: true,
                equalTo: '#password'
            }
        },
        messages: {
            password_confirm: {
                equalTo: msg['passwordnotmatchwithconfirm']
            }
        },
        submitHandler: function(form) {
            const $form = $(form);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_OK']);
                    } else {
                        showMessageBox('danger', msg[data.errors.message]);
                    }
                },
                complete: function (data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });

    $('#1to2step').click(function(event) {
        var form = $('#create-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                $('#idnewotp').val(data.id);
                $('#qrcode').attr('src','data:image/png;base64, ' + data.data);
                $('#firstStep').hide();
                $('#secondStep').show();
                console.log(data);
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#enableOTP').click(function(event) {
        var form = $('#enable-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#deleteOTP').click(function(event) {
        var form = $('#delete-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
	});

    $('#profile-consent-form').submit(function(event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents':[]
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function(data) {

            },
            success: function(data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
            }
        });
    });

    $('.cancel-return-request-form').submit(function(event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/profile/returnrequests/1';
                } else {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });
}