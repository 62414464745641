function footerComponent() {
    //footer
    $('.footer-menu').on('click tap', function() {
        $(this).siblings('.footer-column-items').slideToggle();
        var arrow = $(this).children('.footer-menu-arrow-container').children('.footer-menu-arrow');
        if (arrow.hasClass('open'))
            arrow.removeClass('open');
        else
            arrow.addClass('open');
    });
}
