function catalogPage() {
    'use strict';

    $('html').on('catalog:reload', reloadCatalog);
    $(window).on('popstate', reloadCatalog);

    $('html')
        .on('options-view:toggle', function (event, id) {
            $('.options-view').not(id).removeClass('open');
            $(id).toggleClass('open');
        })
        .on('click', function (event) {
            const target = $(event.target);
            const targetIsFilter = target.is('.options-view') || exists(target.closest('.options-view'));
            if (!targetIsFilter) {
                const id = '#' + $(this).closest('.options-view').attr('id');
                $(this).trigger('options-view:toggle', id);
            }
        });

    setupCatalog();
    $('#catalog-container').removeClass('loading');

    function checkPreferredLayout() {
        var layout = localStorage.getItem('catalogLayout');
        if (layout && layout === 'list') {
            $('#list-option').prop('checked', true).trigger('click');
        }
        else {
            layout = 'grid';
            $('#grid-option').prop('checked', true).trigger('click');
        }

        setCatalogLayout(layout);
    }

    function setCatalogLayout(layout) {
        if (layout === 'grid') {
            $('#catalog-wrapper .list-layout').hide();
            $('#catalog-wrapper .grid-layout').fadeIn();
        } else {
            $('#catalog-wrapper .grid-layout').hide();
            $('#catalog-wrapper .list-layout').fadeIn();
        }

        localStorage.setItem('catalogLayout', layout);
    }

    function setLayoutOptions() {
        $('#layout-options input[name="options"]').on('change', function () {
            const layout = $(this).val();
            setCatalogLayout(layout);
        });
    }

    function setFilterAndSortOptions() {
        $('.options-view .name').on('click', function () {
            const id = '#' + $(this).closest('.options-view').attr('id');
            $(this).trigger('options-view:toggle', id);
        });

        $('.options-view li>a').on('click', function (event) {
            event.preventDefault();
            const url = $(this).attr('href');
            pushAndReload(this, url);
        });

        $('#filter-options.open .name, #mobile-filters .close-mobile-menu .button, #mobile-filters .overlay').on('click', function () {
            if (getQueryParam('filters') == 1) {
                window.history.pushState(null, null, window.location.href.replace('&filters=1', ''));
            }
        });

        $('#mobile-sort, #mobile-filters').find('li>a').on('click', function (event) {
            event.preventDefault();

            const menuId = '#' + $(this).closest('.mobile-menu').attr('id');
            if (menuId == '#mobile-sort') {
                $(this).trigger('mobile-menu:close', menuId);
            }
            const url = $(this).attr('href');
            pushAndReload(this, url);
        });

        setupMobileMenu('#mobile-sort');
        setupMobileMenu('#mobile-filters');
    }

    function setPagination() {
        $('.pagination a').on('click', function (event) {
            event.preventDefault();
            const url = $(this).attr('href');
            pushAndReload(this, url);
            $(window).scrollTop(0);
        });
    }

    function pushAndReload(elem, url) {
        window.history.pushState({}, '', url);
        $(elem).trigger('catalog:reload');
    }

    function setupCatalog() {
        checkPreferredLayout();
        setPagination();
        setFilterAndSortOptions();
        setLayoutOptions();
    }

    function reloadCatalog() {
        $('#catalog-container').addClass('loading');
        $.ajax({
            method: 'GET',
            url: window.location.href,
            dataType: 'html',
            success: function (data) {
                const response = $('<div>').append($(data));
                replaceCatalogElements(response, ['#catalog-wrapper', '#mobile-sort', '#mobile-filters']);
                setProductPopup();
                setupCatalog();
            },
            complete: function () {
                $('#catalog-container').removeClass('loading');
            }
        });
    }

    function replaceCatalogElements(data, selectors) {
        for (let selector of selectors) {
            const element = data.find(selector);
            $(selector).replaceWith(element);
        }
    }

    displaySearchResults();
    function displaySearchResults() {
        const search = decodeURIComponent(getQueryParam('search'));
        if (search) {
            $('#search-keyword').html(search);
            $('.no-search-found').removeClass('hide');

            if (exists('search-result')) {
                $('#search-result').removeClass('hide');
                $('#results-search').html(search);
            }
        }
        else {
            $('.no-products-found').removeClass('hide');

            if (exists('#search-result')) {
                $('#search-result').addClass('hide');
            }
        }
    }
}
