
function showMessageBox(type, message, extra) {
    const messageTimeout = 1200;
    const extraContent = extra ? `<div>${extra}</div>` : '';
    const timestamp = Date.now();

    const messageBox = `
        <div class="alert-box fade in relative">
            <div class="alert-${type}">
                <img id="close-error" class="close-sample" src="/skins/current-skin/images/icons/cancel.svg" alt="cancel"></img>
                <img class="alert-image" src="/skins/current-skin/images/icons/${type}.svg"></img>
                <div class="alert">${message + extraContent}!</div>
            </div>
        </div>`;
    
    $('.alert-box').html(messageBox).show().data('timestamp', timestamp);
    blockPageScroll();

    $('#close-error').on('click', () => closeMessageBox(timestamp));
    setTimeout(() => closeMessageBox(timestamp), messageTimeout);
}

function showQuestionBox(message, action) {
    const messageBox = `
        <div class="alert-box fade in relative">
            <div class="alert-danger">
                <img id="close-error" class="close-sample" src="/skins/current-skin/images/icons/cancel.svg" alt="cancel"></img>
                <div class="alert">${message}</div>
                <div class="flex mt-s">
                    <input type="button" class="btn btn-secondary width-100 mr-xxs close-button" value="${msg['BUTTONS_CANCEL']}">
                    <input type="button" class="btn btn-primary width-100 ml-xxs confirm-button" value="${msg['BUTTONS_CONFIRM']}">
                </div>
            </div>
        </div>`;
    
    $('.alert-box').html(messageBox).show().data('timestamp', 0);
    blockPageScroll();

    $('#close-error, .close-button').on('click', () => closeMessageBox(0));
    $('.alert-box .confirm-button').on('click', action);
}

function closeMessageBox(currentTimestamp) {
    const timestamp = $('.alert-box').data('timestamp');

    if (timestamp == currentTimestamp) {
        $('.alert-box').html('').hide();
        restorePageScroll();
    }
}


$(document).ready(function() {
    
    var errorFound = getQueryParam("error") && (getQueryParam("errorCode") != 263);

    if (errorFound) {
        $('body').addClass('block-scroll')
        $('.alert-box').show();
        $('.close-sample').on('click', function () {
            $('div.alert-box').html('');
            $('.alert-box').hide();
            $('body').removeClass('block-scroll')
        });
        setTimeout(function() {
            $('div.alert-box').html('');
            $('.alert-box').hide();
            $('body').removeClass('block-scroll')
        }, 1200);
    }

});