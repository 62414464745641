function newsletterComponent() {

    $('#newsletter-step2').slideUp(0);
    
    function showStep2() {
        $('#subscribe-newsletter .btn-next').prop('disable', true);
        $('#subscribe-newsletter .btn-next').hide();
        $('#newsletter-step2').slideDown(400);

        loadCaptcha($('#subscribe-newsletter .g-recaptcha'));
    }

    function hideStep2() {
        $('#newsletter-step2').slideUp(400);
        $('#subscribe-newsletter .btn-next').show();
        $('#subscribe-newsletter .btn-next').prop('disable', false);
    }

    $('#subscribe-newsletter input[type="email"]').on('input', function() {
        hideStep2();
    });
    
    $('#subscribe-newsletter').validate({
        rules: {
            email: { required: true, email: true },
            privacy: { required: true }
        },
        messages: {
            email: {
                required: msg['ERR_FILLEMAILFIELD'],
                email: msg['ERR_INVALIDMAIL']
            },
            privacy: msg['ERR_PRIVACY']
        },
        errorPlacement: function(error, element) {
            const id = element.attr('id');
            error.attr('for', id);
            if (element.is('[type=checkbox]')) {    
                error
                    .insertAfter(element.parent());
            }
            else {
                error
                    .css({ 'position': 'relative', 'top': '-8px' })
                    .insertAfter(element);
            }
        },
        submitHandler: function(form) {
            const $form = $(form);

            if ($form.find('.g-recaptcha').is(':visible')) {
                $.ajax({
                    method: $form.attr('method'),
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    dataType: 'json',
                    success: function(data) {
                        if (data.success) {
                            hideStep2();
                            $('#mail2').val('');
                            showMessageBox('success', msg['MSG_SUBSCRIBED']);
                        }
                        else {
                            if (! $('#subscribe-newsletter input[type=email]').valid()) {
                                showMessageBox('danger', msg['ERR_INVALIDMAIL']);
                            }
                            else if (! $('#subscribe-newsletter input[type=checkbox]').is(':checked')) {
                                showMessageBox('danger', msg['ERR_PRIVACY']);
                            }
                            else if (data.errors.message == 'Invalid captcha code') {
                                showMessageBox('danger', msg['ERR_CAPTCHA']);
                            }
                            else if (data.errors.message == 'Login to your account') {
                                showMessageBox('danger', msg['ERR_USER_ALREADY_REGISTERED']);
                            }
                            else {
                                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                            }
                        }
                    },
                    error: function() {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    },
                    complete: function() {
                        grecaptcha.reset();
                        expiredNewsletterCaptcha();
                    }
                });
            }
            else {
                showStep2();
            }
        }
    });
}

function subscribeNewsletterCaptcha() {
    $('#subscribe-newsletter button[type="submit"]').prop('disabled', false);
}

function expiredNewsletterCaptcha() {
    $('#subscribe-newsletter button[type="submit"]').prop('disabled', true);
}