function initTelDropdowns() {
  if (exists('input[name*="cel"]:not([type="hidden"])')) {
      for (const type of ['cel', 'tel']) {
          const prefixInput = $(`input[name*="${type}"][type="hidden"]`);
          const input = $(`input[name*="${type}"]`).not('[type="hidden"]');
      
          if (exists(input)) {
              const prefix = (prefixInput.val().startsWith('+') ? prefixInput.val() : `+${prefixInput.val()}`).replace(/[+]+/g, '+');
              input.val(prefix + input.val());
              const iti = intlTelInput(input[0], {
                  utilsScript: `/skins/vendor/intl-tel-input-utils.js`,
                  separateDialCode: true
              });
              input.on('countrychange', function() {
                  prefixInput.val(iti.getSelectedCountryData().dialCode);
              });
          }
      }
  }
}