function checkoutPage() {
    'use strict';


    $('.carrier-form-checkout').change(function() {
        $.ajax({
            type: 'POST',
            url: "/restful/carrier/change",
            data: {
                country_id: $(this).val()
            },
            headers: {'Accept': "application/json"},
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/checkout/shipping';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('#clausola').on( "click", function() {
        $('#clausola1')[0].checked = $('#clausola')[0].checked
     });

    $('#dispatch-form-btn').on('click', function(event) {
        $('#dispatch-address-form').trigger('submit');
    });
    
    $('#dispatch-address-form').validate({
        errorPlacement: () => {},
        invalidHandler: function() {
            showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
        },
        submitHandler: function(form) {
            if ($('input#intangible').val() == 1) {
                submitFormIntangible(form);
            } else {
                submitForm(form);
            }
        }
    })

    function submitFormIntangible(form) {
        const $form = $(form);
        const emailValue = $('input[name="email"]').val();

        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: {
                email : emailValue,
                country_id: 108,
                street: '-',
                number: 1,
                cap: 10100,
                city: '-',
                addressee: '-',
                state: '-',
                updateType: 'dispatch',
                prov: '-',
                clausola: 'on',
            },
            dataType: 'json',
            success: function() {
                if (typeof dataLayer !== 'undefined' && dataLayer && form.id == 'dispatch-address-form') {
                    dataLayer.push({ ecommerce: null });
                    pushGTMEventWithCallback(500, {
                        event: 'add_shipping_info',
                        ecommerce: window.ecommerce,
                        eventCallback: GTMCallback(function() {
                            window.location.href = $form.data('nextstep');
                        })
                    });
                }
                else {
                    window.location.href = $form.data('nextstep');
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }

    function submitForm(form) {
        const $form = $(form);

        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function() {
                const $billingForm = $('#billing-address-form');
                $.ajax({
                    method: $billingForm.attr('method'),
                    url: $billingForm.attr('action'),
                    data: $billingForm.serialize(),
                    dataType: 'json',
                    success: function(data) {
                        if (data.success) {
                            if (typeof dataLayer !== 'undefined' && dataLayer && form.id == 'dispatch-address-form') {
                                dataLayer.push({ ecommerce: null });
                                pushGTMEventWithCallback(500, {
                                    event: 'add_shipping_info',
                                    ecommerce: window.ecommerce,
                                    eventCallback: GTMCallback(function() {
                                        window.location.href = $billingForm.data('nextstep');
                                    })
                                });
                            }
                            else {
                                window.location.href = $billingForm.data('nextstep');
                            }
                        }
                        else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    },
                    error: function() {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                });
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }

    $('#billing-address-form').on('submit', function(event) {
        event.preventDefault();
        event.stopPropagation();
    });

    $('#same-billing').on( "click", function() {
        $('#billing-address-form').slideUp();
    });

    $('#different-billing').on( "click", function() {
        $('#billing-address-form').slideDown();
    });

    $('[id*="setefi-form"]').on('submit', function (event) {
        event.preventDefault();
        const $form = $(this);
        $.ajax({
            method : $form.attr('method'),
            url : $form.attr('action'),
            async: false,
            dataType : 'json',
            success : function(data) {
                if (data.success) {
                    window.location.href = data.message;
                }
            }
        });
    });

    $('[name*="country"]').on('change', updateProvinces);
    updateProvinces();
    
    function updateProvinces() {
        $('[name*="prov"]').each(function() {
            const $this = $(this);
            const country = $this.closest('form').find('[name*="country"]');
    
            if (country.length > 0) {
                loadProvinces(country.val()).then(function(provinces) {
                    if (provinces.length > 0) {
                        const id = $this.attr('id');
                        const name = $this.attr('name');
                        const type = $this.attr('type');
                        const value = $this.val();
                        const placeholder = $this.attr('placeholder');
                        let options = [];
                        let hasSelectedValue = false;
    
                        for (let i = 0; i < provinces.length; i++) {
                            const province = provinces[i];
                            const selected = (province.code == value) ? 'selected' : '';
                            options.push(`<option value="${province.code}" ${selected}>${province.name} (${province.code})</option>`);
                            hasSelectedValue = hasSelectedValue || (province.code == value);
                        }
                        if (!hasSelectedValue) {
                            options[0].replace('  ', ' selected');
                        }
                        $this.replaceWith(`
                            <select id="${id}" class="select carrier-form-checkout" name="${name}" data-type="${type}" data-placeholder="${placeholder}" data-value="${value}" required aria-required="true">
                                ${options.join('\n')}
                            </select>
                        `);
                    }
                    else if ($this.is('select')) {
                        const id = $this.attr('id');
                        const name = $this.attr('name');
                        const type = $this.data('type');
                        const value = $this.data('value');
                        const placeholder = $this.data('placeholder');
                        $this.replaceWith(`<input id="${id}" type="${type}" name="${name}" value="${value}" placeholder="${placeholder}" required aria-required="true"/>`);
                    }
                });
            }
        });
    }

    $('#booking-btn').on('click', function(event) {
        const btn = this;

        if (typeof dataLayer !== 'undefined' && dataLayer) {
            event.preventDefault();
            dataLayer.push({ ecommerce: null });
            pushGTMEventWithCallback(500, {
                event: 'add_payment_info',
                ecommerce: window.ecommerce('Booking'),
                eventCallback: GTMCallback(function() {
                    const form = btn.closest('form');
                    if (form.requestSubmit) {
                        form.requestSubmit();
                    }
                    else {
                        form.submit();
                    }
                })
            });
        }
    });

    $('#cod-btn').on('click', function(event) {
        const btn = this;

        if (typeof dataLayer !== 'undefined' && dataLayer) {
            event.preventDefault();
            dataLayer.push({ ecommerce: null });
            pushGTMEventWithCallback(500, {
                event: 'add_payment_info',
                ecommerce: window.ecommerce('COD'),
                eventCallback: GTMCallback(function() {
                    const form = btn.closest('form');
                    if (form.requestSubmit) {
                        form.requestSubmit();
                    }
                    else {
                        form.submit();
                    }
                })
            });
        }
    });
}